$(function () {
  $('[data-toggle="popover"]').popover({
      placement : 'top',
      trigger : 'hover'
  })

  // var first_name = $('#lead_first_name');
  // if(!first_name.val()) {
  //   first_name.focus();
  // } else{
  //   first_name.blur();
  // }
})
